<template>
    <div>
        <component :is="targetComponent()" :brandConfig="brandConfig" :country="country" :storeId="storeId"
            :storeName="storeName" />
    </div>
</template>

<script>
export default {
    components: {
        MY: () => import("./MY/Registration.vue"),
        TH: () => import("./TH/Registration.vue"),
        PH: () => import("./PH/Registration.vue"),
        frontierMY: () => import("./MY/FrontierRegistration.vue"),
        frontierTH: () => import("./TH/FrontierRegistration.vue"),
        frontierPH: () => import("./PH/FrontierRegistration.vue"),
    },
    props: {
        brandConfig: {
            type: Object,
            default: () => {
                return {};
            },
        },
        country: {
            type: String,
            default: "",
        },
        storeId: {
            type: String,
            default: "",
        },
        storeName: {
            type: String,
            default: "",
        },
    },
    created() {
    },
    data() {
        return {
            componentMapping: {
                MY: "MY",
                TH: "TH",
                PH: "PH"
            },
            frontierComponentMapping: {
                MY: "frontierMY",
                TH: "frontierTH",
                PH: "frontierPH"
            },
            frontierCountryBrandMapping: {
                MY: ["bobbibrown", "clinique", "esteelauder", "mac", "lamer", "jomalone", "tomford", "origins", "companycorporatesales", "lelabo"],
                TH: ["companycorporatesales","esteelauder","mac","lelabo"],
                PH: ["lelabo","mac","lamer", "multibrand"],
            }
        };
    },
    watch: {},
    methods: {
        targetComponent() {
            if (this.frontierCountryBrandMapping[this.country].includes(this.brandConfig.name)) {
                return this.frontierComponentMapping[this.country]
            }
            return this.componentMapping[this.country]
        }
    }
}
</script>

<style scoped></style>
